<script>
import brandable from '~/mixins/brandable'
import { dataSource } from '~/routes/VDP/data-source'

export default {
  mixins: [
    brandable
  ],
  computed: {
    dealerLogo () {
      if (this.$route.name === 'vdp' && dataSource.dealer.logo?.url) {
        return dataSource.dealer.logo?.url
      }

      return this.$route.meta?.logo || this.selectedLogo
    },
    dealerName () {
      if (this.$route.name === 'vdp' && dataSource.dealer.name) {
        return dataSource.dealer.name
      }

      return this.$store.state.instance.name
    }
  },
  methods: {
    startNewSearch () {
      this.$store.dispatch('search/reset')
    }
  }
}
</script>

<template>
  <header class="navigation--primary--showroom">
    <div
      v-if="$route.name !== 'srp'"
      class="surface surface--elevation-0"
    >
      <router-link to="/">
        <img
          class="navigation--primary--showroom__logo"
          :alt="dealerName"
          :src="dealerLogo"
        >
      </router-link>
    </div>
    <div
      v-if="$route.name === 'srp'"
      class="surface surface--elevation-0"
    >
      <router-link to="/">
        <img
          class="navigation--primary--showroom__logo"
          :alt="dealerName"
          :src="selectedLogo"
        >
      </router-link>
      <VBtn
        class="ml-12 hidden-sm-and-down"
        color="primary"
        depressed
        @click="startNewSearch"
      >
        Start Over
      </VBtn>
    </div>
  </header>
</template>

<style lang="scss">
.navigation--primary--showroom {
  height: 55px;

  @include breakpoint('md-and-up') {
    height: 71px;
  }

  .surface {
    align-items: center;
    border-radius: 0;
    display: flex;
    height: 56px;
    line-height: 0;
    max-width: 1400px;
    padding: 4px 16px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;

    @include breakpoint('md-and-up') {
      height: 72px;
      padding: 8px 24px;

      @supports (backdrop-filter: saturate(180%) blur(5px)) {
        backdrop-filter: saturate(180%) blur(5px);
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  &::after {
    content: " ";
    display: block;
    height: 71px;
  }

  &__logo {
    max-height: 48px;
    max-width: 310px;

    @include breakpoint('md-and-up') {
      max-height: 56px;
    }
  }

  .v-btn {
    text-transform: none;
  }
}
</style>
