<script>
import Logo from '@maxsystems/ui/logos/wordmark'

export default {
  components: {
    Logo
  }
}
</script>

<template>
  <footer
    class="footer primary white--text py-6"
  >
    <VLayout
      row
      wrap
    >
      <VFlex
        class="footer__powered-by d-flex"
        md4
        xs12
        font-size-xxs
        font-weight-light
      >
        <div class="footer__powered-by__text">
          Powered by
        </div>
        <Logo
          flat
          height="30"
          color-secondary="#FFF"
        />
      </VFlex>
      <VFlex
        md4
        xs12
        px-6
        text-left
        text-md-center
        font-size-xxs
        font-weight-light
      >
        &copy; {{ new Date().getFullYear() }} MAX Digital, LLC. All Rights Reserved.
        <div class="mb-1" />
        <a
          href="/disclaimers"
          class="secondary--text"
        >
          Pricing Information and Disclaimer
        </a>
      </VFlex>
      <VFlex
        v-if="$route.path !== '/'"
        class="footer__change-dealership"
        md4
        xs12
        px-6
        pb-2
        text-md-right
      >
        <VBtn
          color="secondary"
          class="footer__change-dealership__btn ma-0"
          depressed
          large
          to="/"
        >
          Change Dealership
        </VBtn>
      </VFlex>
    </VLayout>
  </footer>
</template>

<style lang="scss">
.footer {
  a {
    text-decoration: none;
  }

  &__powered-by {
    padding-left: spacer(6);
    padding-right: spacer(6);

    @include breakpoint('sm-and-down') {
      padding-bottom: spacer(6);
    }

    &__text {
      display: inline;
      vertical-align: text-bottom;
    }
  }

  &__change-dealership {
    @include breakpoint('sm-and-down') {
      padding-top: spacer(6);
    }

    &__btn {
      font-weight: normal;
      text-transform: none;
    }
  }
}
</style>
